<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-w="12">
      <vs-col vs-w="3" class="px-2">
        <tecnicoLista @update="selectTec" />
      </vs-col>

      <vs-col vs-w="9" class="px-2">
        <div id="turno_card" class="vs-con-loading__container">
          <vx-card class="w-full">
            <div>
              <vs-row vs-w="12" vs-type="flex" vs-justify="space-between">
                <vs-col vs-w="10.5" class="">
                  <vs-col vs-w="4">
                    <h5>Gerenciar Força</h5>
                  </vs-col>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-justify="right"
                  vs-align="right"
                  class="px-2"
                >
                  <vs-button
                    size="small"
                    color="success"
                    type="relief"
                    :disabled="
                      !$atividades.permissoes.includes(14) ||
                      selectedTec == null
                    "
                    @click="addTurnos()"
                    >Associar</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
            <div class="w-full">
              <vs-row
                vs-w="12"
                vs-type="flex"
                class="w-full"
                v-for="(dia, dayIndex) in dias"
                :key="dayIndex"
              >
                <vx-card class="my-2 py-1" no-shadow card-border>
                  <div>
                    <h4>{{ dia.nome }}</h4>
                  </div>
                  <vs-col
                    vs-w="2"
                    class="ml-5"
                    vs-justify="left"
                    vs-align="left"
                    v-for="(turno, index) in turnos"
                    :key="index"
                  >
                    <div class="vx-row" :key="reRender">
                      <h5>{{ turno.nome }}:</h5>
                      <div class="vx-col">
                        <vs-switch
                          color="success"
                          v-model="dias[dayIndex].turnosSelecionados[index]"
                          @click="addToTec(index, dayIndex)"
                        />
                      </div>
                    </div>
                  </vs-col>
                </vx-card>
              </vs-row>
            </div>
          </vx-card>
        </div>
      </vs-col>
      <vs-divider
        position="center"
        color="warning"
        class="pt-5 pb-0 my-0 mt-5"
      ></vs-divider>
      <vs-col vs-w="12" class="px-2 mt-5">
        <div id="turno_card" class="vs-con-loading__container">
          <vx-card class="w-full">
            <div>
              <vs-row vs-w="12" vs-type="flex" vs-justify="space-between">
                <vs-col vs-w="10.5" class="">
                  <vs-col vs-w="4">
                    <h5>Dividir Força</h5>
                  </vs-col>
                </vs-col>
                <vs-col
                  vs-w="1.5"
                  vs-justify="right"
                  vs-align="right"
                  class="px-2"
                >
                  <vs-button
                    size="small"
                    color="success"
                    type="relief"
                    :disabled="
                      !$atividades.permissoes.includes(14) ||
                      selectedTec == null
                    "
                    @click="associarFatorInicial()"
                    >Associar</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
            <div class="w-full">
              <vs-row
                vs-w="12"
                vs-type="flex"
                class="w-full"
                v-for="(dia, dayIndex) in divisaoInicial"
                :key="dayIndex"
              >
                <vx-card class="my-2 py-1" no-shadow card-border>
                  <div>
                    <h4>{{ dia.nome }}</h4>
                  </div>
                  <vs-col
                    vs-w="2.2"
                    class="ml-5"
                    vs-justify="left"
                    vs-align="left"
                    v-for="(turno, index) in dia.turnos"
                    :key="index"
                  >
                    <div class="vx-row" :key="reRender">
                      <h6>
                        {{ turno.nome }} | Manut:
                        {{ forcaTotal[dayIndex].turnos[index].forca }}h /
                        Inicial:
                        {{ forcaTotal[dayIndex].turnos[index].forcaInicial }}h:
                      </h6>
                      <div class="vx-col">
                        <vs-col vs-w="12" vs-justify="center" vs-align="center">
                          <div>
                            <vs-input
                              size="small"
                              color="warning"
                              label-placeholder="divisão inicial"
                              class="mb-2"
                              @change="updateForca(dayIndex, index)"
                              v-model="turno.fatorInicial"
                            ></vs-input>
                          </div>
                        </vs-col>
                      </div>
                    </div>
                  </vs-col>
                </vx-card>
              </vs-row>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import tecnicoLista from "./components/tecnicoLista.vue";
import draggable from "vuedraggable";
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      tecIni: false,
      dias: [
        { nome: "Segunda", turnosSelecionados: [] },
        { nome: "Terça-feira", turnosSelecionados: [] },
        { nome: "Quarta-feira", turnosSelecionados: [] },
        { nome: "Quinta-feira", turnosSelecionados: [] },
        { nome: "Sexta-feira", turnosSelecionados: [] },
        { nome: "Sábado", turnosSelecionados: [] },
        { nome: "Domingo", turnosSelecionados: [] },
      ],
      forcaTotal: null,
      reRender: 1,
      selectedTec: null,
      turnos: [],
      turnosSelecionados: [],
      divisaoInicial: null,
    };
  },
  methods: {
    async updateForca(dayIndex, index) {
      this.forcaTotal[dayIndex].turnos[index].forca =
        this.forcaTotal[dayIndex].turnos[index].forcaOriginal -
        this.divisaoInicial[dayIndex].turnos[index].horas *
          this.divisaoInicial[dayIndex].turnos[index].fatorInicial;

      this.forcaTotal[dayIndex].turnos[index].forcaInicial =
        this.divisaoInicial[dayIndex].turnos[index].horas *
        this.divisaoInicial[dayIndex].turnos[index].fatorInicial;
    },
    async selectTec(tec) {
      this.selectedTec = tec.id;
      await this.getTurnoByTecnico(tec.id);
    },
    async addTurnos() {
      await this.$vs.loading({ container: "#turno_card", scale: 0.6 });
      try {
        await this.$http.post("tecnico_turno", {
          id_colaborador: this.selectedTec,
          dias: this.dias,
          tecIni: this.tecIni,
        });

        if (this.selectedTec == undefined || this.selectedTec == null) {
          await this.getTurnos();
        } else {
          await this.getTurnoByTecnico(this.selectedTec);
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#turno_card > .con-vs-loading");
      }
    },
    async addToTec(index, dia) {
      const turnos = this.dias[dia].turnosSelecionados;
      let have = turnos[index];
      have = have == false ? true : false;
      turnos[index] = have;
      //this.turnosSelecionados = turnos;
      this.dias[dia].turnosSelecionados = turnos;
    },
    async getTurnoByTecnico(tecnico) {
      await this.$vs.loading({
        container: "#turno_card",
        scale: 0.6,
      });
      for (let i in this.dias) {
        this.dias[i].turnosSelecionados = this.dias[i].turnosSelecionados.map(
          () => false
        );
        try {
          const res = await this.$http.post(`tecnico_by_day`, {
            id_colaborador: tecnico,
            dia: i,
          });
          const turnos = await this.dias[i].turnosSelecionados;
          for (let i = 0; i < res.length; i++) {
            const index = this.turnos.map((x) => x.id).indexOf(res[i].id_turno);
            turnos[index] = true;
          }
          await this.$set(this.dias[i].turnosSelecionados, turnos);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        }
      }
      await this.$vs.loading.close("#turno_card > .con-vs-loading");
    },
    async getTurnosBySelectedTec(event) {
      // this.turnosSelecionados = this.turnosSelecionados.map(
      //   () => false
      // );
      await this.$vs.loading();

      for (let i in this.dias) {
        try {
          const res = await this.$http.get(`tecnico_turno/${event}`);
          const turnos = this.dias[i].turnosSelecionados;
          for (let i = 0; i < res.length; i++) {
            const index = this.turnos.map((x) => x.id).indexOf(res[i].id);
            turnos[index] = true;
          }
          this.$set(this.dias[i].turnosSelecionados, turnos);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        }
        await this.$vs.loading.close();
      }
    },
    async getTurnos() {
      await this.$vs.loading();
      this.turnos = await this.$http.get("turnos");
      //this.turnosSelecionados = [];
      for (let i = 0; i < this.turnos.length; i++) {
        for (let i in this.dias) {
          this.dias[i].turnosSelecionados.push(false);
        }
      }
      await this.$vs.loading.close();
    },
    async getForcaTotal() {
      await this.$vs.loading();

      this.forcaTotal = await this.$http.get("getForcaTotalDiaTurno");
      await this.$vs.loading.close();
    },
    async getFatorInicialTotal() {
      await this.$vs.loading();
      this.divisaoInicial = await this.$http.get("getFatorInicialTotal");
      for (let dia in this.divisaoInicial) {
        for (let turno in this.divisaoInicial[dia].turnos) {
          await this.updateForca(dia, turno);
        }
      }
      await this.$vs.loading.close();
    },
    async associarFatorInicial() {
      await this.$vs.loading();
      try {
        await this.$http.post("associarFatorInicial", this.divisaoInicial);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close();
    },
  },
  async mounted() {
    await this.$vs.loading();
    await this.getForcaTotal();
    await this.getFatorInicialTotal();
    await this.getTurnos();
    await this.$vs.loading.close();
  },
  components: {
    ...components,
    tecnicoLista,
    draggable,
  },
};
</script>
