<template>
  <div>
    <vx-card card-background="#021a33">
      <vs-row vs-type="flex">
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
          <div>
            <h5 style="color: white">Tecnicos: {{ tecnicos.length }}</h5>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <VuePerfectScrollbar
      class="scroll-tecnicos-lista"
      :settings="settings"
      style="background-color: #ebebeb;"
    >
      <div id="levantamento_lista" class="vs-con-loading__container">
        <div v-if="!tecnicos.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important">
                <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
              </span>
            </ul>
          </div>
        </div>
        <vs-list v-for="(tecnico, idx) in tecnicos" v-bind:key="idx">
          <vx-card
            :class="blocked == false ? (selectedTec !== tecnico.id ? 'tecnico' : 'active-tecnico') : (selectedTec !== tecnico.id ? 'tecnico-disabled' : 'active-tecnico')"
            @click="selectTec(tecnico), blocked == false ? selectedTec = tecnico.id : 1"
          >
            <template slot="no-body">
              <div>
                <div class>
                  <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-col
                      v-if="tecnico.nome"
                      class="pt-3"
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4>
                        <b>{{ tecnico.nome }}</b>
                      </h4>
                    </vs-col>
                    <!-- <vs-col
                      v-if="tecnico.nome"
                      class="py-0"
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h6>
                        3 servicos associados
                      </h6>
                    </vs-col>-->
                  </vs-row>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-list>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import draggable from "vuedraggable";

export default {
  props: { blocked: Boolean },
  data() {
    return {
      selected: {},
      selectedTec: 0,
      cardClass: "tecnico-card overflow-hidden",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      novoClienteModal: false,
      tecnicos: {}
    };
  },

  methods: {
    async selectTec(tecnico) {
      if (!this.blocked) {
        this.selected.id = tecnico.id;
        this.selected.nome = tecnico.nome;

        await this.$emit("update", this.selected);
      }
    },
    async getTecnicos() {
      const atividades = [10, 18];
      this.$vs.loading();
      try {
        this.tecnicos = await this.$http.post(
          `colaboradorAtividade`,
          atividades
        );
        await this.selectTec(this.tecnicos[0]);
        this.selectedTec = this.tecnicos[0].id;
        await this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
        await this.$vs.loading.close();
      }
    }
  },
  components: { ...components, draggable },
  async mounted() {
    await this.getTecnicos();
  }
};
</script>

<style lang="scss" scoped>
.tecnico-disabled {
  background-color: #f0f0f0;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: not-allowed;
  h4,
  h6 {
    color: #7c7c7c;
  }
}
.tecnico {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #ffca00 !important;
}
.tecnico:hover {
  transition: 0.1s;
  background: #eeeeee;
  transform: translateY(-3px);
}
.active-tecnico {
  background-color: #00bba2;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}
.scroll-tecnicos-lista {
  max-height: 65vh !important;
}
</style>
